<template>
  <section class="content-header">
    <h1>
      <div class="d-flex justify-content-between">
        {{headers.title}}
        <a v-if="headers.url && headers.url != 'order' && headers.url != 'programming'" :href="'/cms/' + headers.url + '/show'">
          <button type="button" class="btn btn-primary btn-sm">Novo registro</button>
        </a>
      </div>
    </h1>
    <small v-if="headers.subtitle">{{headers.subtitle}}</small>
  </section>
</template>

<script>
export default {
  props: ["headers"],
  methods: {}
};
</script>
