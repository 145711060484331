<template>
  <div class="content">
    <div class="spinner" v-if="loading"></div>
    <div class="chart" v-if="!loading">
      <div class="title">
        <span>Produtividade</span>
      </div>
      <div class="info-area">
        <div class="scroll">
          <p v-for="item in items" :key="item.id">
            <span>{{ item.name }}</span>
            <span style="text-transform: lowercase">{{ item.averange }} m/h</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.content {
  .chart {
    display: block;
    height: 100%;
    width: 100%;
    .title {
      height: 10%;
      width: 100%;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      transform: translateY(-10px);
      font-size: 32px;
    }
    .info-area {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      width: 100%;
      height: 90%;
      overflow: hidden;
      font-size: 20px;
      &:hover {
        overflow: auto;
        scrollbar-color: #577186 #17253f;
        scrollbar-width: thin;
      }
      &::-webkit-scrollbar-track {
        background-color: #17253f;
      }
      &::-webkit-scrollbar,
      &::-webkit-scrollbar-thumb {
        width: 5px;
        border-radius: 10px;
        background-color: rgba(255, 255, 255, 0.3);
      }
      p {
        width: 100%;
        display: inline-flex;
        margin-bottom: 2%;
        span {
          width: 40%;
          padding-left: 2%;
          color: #67ebb9;
          &:first-child {
            text-align: right;
            padding-left: 0;
            padding-right: 2%;
            border-right: 2px solid rgba(255, 255, 255, 1);
            color: #fff;
            font-weight: 200;
            width: 60%;
          }
        }
      }
    }
  }
}
@media (max-width: 1440px) {
  .content .chart {
    .title {
      font-size: 24px;
    }
    .info-area {
      font-size: 14px;
    }
  }
}
</style>

<script>
import axios from "axios";
export default {
  props: ["date", "machine"],
  data() {
    return {
      loading: true,
      items: [],
    };
  },
  methods: {
    getData(spin) {
      spin == true ? this.loading = true : '';
      let data = { date: this.date, machine: this.machine };
      axios
        .post("api/dashboard/get-productivity", data)
        .then((response) => {
          this.loading = false;
          this.items = response.data;
          this.timeoutAtt();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    timeoutAtt() {
      setTimeout(() => {
        this.getData();
      }, 120000);
    },
  },
  mounted: function () {
    this.getData();
  },
  watch: {
    date() {
      this.getData(true);
    },
    machine() {
      this.getData(true);
    },
  },
};
</script>