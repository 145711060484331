<template>
  <div class="content">
    <div class="spinner" v-if="loading"></div>
    <div class="machines" v-if="!loading">
      <div class="title">
        <span>Máquinas</span>
      </div>
      <div class="info-area">
        <div v-for="item in items" :key="item.id">
          <div class="light">
            <span
              class="light red"
              v-if="item.working == 'off'"
            ></span>
            <span
              class="light yellow"
              v-if="item.working == 'pause'"
            ></span>
            <span
              class="light green"
              v-if="item.working == 'on'"
            ></span>
          </div>
          <p class="name">{{ item.name }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.content .machines {
  display: block;
  height: 100%;
  width: 100%;
  .title {
    height: 10%;
    width: 100%;
    font-size: 32px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateY(-10px);
  }
  .info-area {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    margin: auto;
    width: 95%;
    height: 90%;
    overflow: hidden;
    font-size: 18px;
    &:hover {
      overflow: auto;
      scrollbar-color: #577186 #17253f;
      scrollbar-width: thin;
      div {
        padding-right: 0;
      }
    }
    &::-webkit-scrollbar-track {
      background-color: #17253f;
    }
    &::-webkit-scrollbar,
    &::-webkit-scrollbar-thumb {
      width: 5px;
      border-radius: 10px;
      background-color: rgba(255, 255, 255, 0.3);
    }
    div {
      display: inline-flex;
      align-items: center;
      width: 100%;
      font-weight: 200;
      margin-bottom: 2%;
      padding-right: 5px;
      .name {
        width: calc(100% - 12px);
        padding-left: 2%;
      }
      .light {
        width: 12px;
        margin: 0;
        padding: 0;
        text-align: right;
        opacity: 1;
        span {
          width: 12px;
          height: 12px;
          border-radius: 100%;
        }
        .red {
          background-color: #d3312e;
          box-shadow: 0 0 10px 0 #832321;
        }
        .green {
          background-color: #208343;
          box-shadow: 0 0 10px 0 #124624;
        }
        .yellow {
          background-color: #dfa01e;
          box-shadow: 0 0 10px 0 #b6821a;
        }
        // .animate {
        //   animation: blink 2s ease-in-out infinite;
        // }
      }
    }
  }
}
@media (max-width: 1440px) {
  .content .machines {
    .title {
      font-size: 24px;
    }
    .info-area {
      font-size: 14px;
      .schedule {
        font-size: 12px;
      }
    }
  }
}
// @keyframes blink {
//   0% {
//     opacity: 1;
//   }
//   50% {
//     opacity: 0.5;
//   }
// }
</style>

<script>
import axios from "axios";
export default {
  props: ["machine"],
  data() {
    return {
      loading: true,
      items: [],
    };
  },
  methods: {
    getData(spin) {
      // this.items.forEach((item) => {
      //   item.blink = false;
      // });
      spin == true ? this.loading = true : '';
      let data = {
        machine: this.machine
      }
      axios
        .post("api/dashboard/get-machines", data)
        .then((response) => {
          this.items = response.data;
          this.loading = false;
          // this.restartAnimation();
          this.timeoutAtt();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // restartAnimation() {
    //   this.items.forEach((item) => {
    //     item.working == 'pause' ? item.blink = true : '';
    //   });
    // },
    timeoutAtt() {
      setTimeout(() => {
        this.getData();
      }, 30000);
    },
  },
  mounted() {
    this.getData();
  },
  watch: {
    machine() {
      this.getData(true);
    },
  },
};
</script>