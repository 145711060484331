<template>
  <div class="box box-widget">
    <div class="box-header mb-3">
      <div class="row d-flex align-center">
        <div class="col-md-4 col-sm-12">
          <form
            id="formDate"
            method="GET"
            class="form-horizontal"
            :action="url"
          >
            <input type="hidden" name="date" :value="dateFilter" />
            <v-menu
              v-model="menu1"
              :close-on-content-click="false"
              max-width="290"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="computedDateFormattedMomentjs"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  hide-details
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date"
                @change="menu1 = false"
                locale="pt-br"
              ></v-date-picker>
            </v-menu>
          </form>
        </div>
        <div class="col-md-3 col-sm-12">
          <v-select
            no-data-text="Nenhuma fábrica cadastrada"
            label="Unidade Fabril"
            color="#858585"
            :items="factories"
            item-value="id"
            item-text="name"
            :loading="loadingFactories"
            :disabled="loadingFactories"
            clearable
            hide-details
            v-model="factory"
            flat
            @change="getMachines()"
          ></v-select>
        </div>
        <div class="col-md-3 col-sm-12">
          <v-select
            no-data-text="Nenhuma máquina cadastrada"
            label="Todas as Máquinas"
            color="#858585"
            :items="machines"
            item-value="id"
            item-text="name"
            :loading="loadingMachines"
            :disabled="loadingMachines || !factory"
            clearable
            hide-details
            v-model="machine"
          ></v-select>
        </div>
        <!-- <div class="col-md-5 col-sm-12">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            placeholder="Busca por, Número Pedido, Produto, OP, Cliente ..."
            hide-details
            outlined
          ></v-text-field>
        </div> -->
      </div>
    </div>
    <form
      v-on:submit.prevent="confirmDelete"
      :action="url + '/destroy'"
      method="POST"
    >
      <input type="hidden" name="_token" :value="token" />
      <input type="hidden" name="_method" value="DESTROY" />
      <div class="box-body">
        <v-row class="ml-0 mr-0">
          <v-col
            cols="12"
            sm="12"
            md="12"
            class="pl-0 pr-5"
            v-for="(item, index) in filteredItems"
            :key="index"
          >
            <v-card class="mb-1 pa-0 card_machine" min-height="100" flat>
              <v-card-title class="pa-2 header">
                {{ item.name }}
                <v-spacer></v-spacer>
                {{ item.orders.length }} OP &nbsp;
                <button type="button" id="btn-edit" @click="showEdit(item)"><v-icon small color="white">mdi-pencil</v-icon></button>
              </v-card-title>
              <v-card-text class="pa-0 pb-1">
                <v-row class="ma-0 mb-3">
                  <v-progress-linear
                    :value="item.power"
                    color="#5cb85c"
                    height="20"
                  >
                    <v-chip
                      small
                      color="#5cb85c"
                      v-if="item.power"
                      class="text-white font-s"
                      >{{ item.movimento }} Metros</v-chip
                    >
                  </v-progress-linear>
                </v-row>
                <v-row
                  class="ma-0 line"
                  v-for="(order, index) in item.orders"
                  :key="index"
                  style="font-size: 18px; cursor: pointer"
                >
                  <v-col
                    cols="12"
                    sm="12"
                    md="1"
                    class="d-flex align-center pl-1"
                    @click="showDialogDetails(order)"
                  >
                    {{ order.nr_ordem }}
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="5"
                    class="d-flex align-center flex-column"
                    @click="showDialogDetails(order)"
                    style="font-size: 14px"
                  >
                    <v-col cols="12">
                      {{ order.descricao_produto }}
                    </v-col>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="3"
                    class="d-flex align-center pl-1"
                    @click="showDialogDetails(order)"
                  >
                    <!-- {{order.nome_pessoa}} -->
                    {{
                      order.play_logs.length > 0
                        ? order.play_logs[order.play_logs.length - 1].user.name
                        : "-"
                    }}
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="2"
                    class="d-flex align-center pr-1"
                    @click="showDialogDetails(order)"
                  >
                    <v-progress-linear
                      :value="100"
                      color="#5cb85c"
                      height="20"
                      striped
                      rounded
                      v-if="order.status == 3"
                    >
                      <strong class="label_progress">{{
                        order.updated
                      }}</strong>
                    </v-progress-linear>
                    <v-progress-linear
                      :value="100"
                      color="#f0ad4e"
                      height="20"
                      rounded
                      v-if="order.status == 2"
                    >
                      <strong class="label_progress">Aguardando</strong>
                    </v-progress-linear>
                    <v-progress-linear
                      :value="100"
                      color="#d9534f"
                      height="20"
                      striped
                      rounded
                      v-if="order.status == 4"
                    >
                      <strong class="label_progress">{{
                        order.updated
                      }}</strong>
                    </v-progress-linear>
                    <v-progress-linear
                      :value="100"
                      color="#0275d8"
                      height="20"
                      striped
                      rounded
                      v-if="order.status == 5"
                    >
                      <strong class="label_progress">{{
                        order.updated
                      }}</strong>
                    </v-progress-linear>
                  </v-col>

                  <v-col
                    cols="12"
                    sm="12"
                    md="1"
                    class="d-flex align-center pr-1"
                  >
                    <a
                      v-if="item.orders[index].status == 2"
                      @click="confirmDelete(item)"
                    >
                      <v-icon
                        medium
                        color="red"
                      >mdi-delete</v-icon>
                    </a>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="12"
            class="pl-0 pr-5 text_nenhum"
            v-if="!filteredItems.length"
          >
            Nenhum registro encontrado
          </v-col>
        </v-row>
      </div>
    </form>

    <!-- DIALOG DETALHES -->
    <v-dialog v-model="dialogDetails" max-width="800" persistent scrollable>
      <v-card flat min-height="300" max-height="700" v-if="details">
        <v-card-title class="ma-2">
          OP {{ details.nr_ordem }}
          <v-spacer></v-spacer>
          <v-btn icon text @click="dialogDetails = false">
            <v-icon>mdi-close-thick</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="mt-3 text-center">
          <label v-if="details.play_logs.length == 0"
            >Nenhum registro para esta ordem</label
          >
          <v-row
            class="ma-0 line"
            v-for="(log, index) in this.details.play_logs"
            :key="index"
          >
            <v-col cols="12" sm="12" md="4" class="pl-1">
              {{ log.date_time }}
            </v-col>
            <v-col cols="12" sm="12" md="4" class="pl-1">
              {{ log.user.name }}
            </v-col>
            <v-col cols="12" sm="12" md="4" class="d-flex align-center pr-1">
              <v-progress-linear
                :value="100"
                color="#5cb85c"
                height="20"
                striped
                rounded
                v-if="log.action == 'Iniciado' || log.action == 'Iniciado com atraso'"
              >
                <strong class="label_progress" style="color: #fff">{{
                  log.action
                }}</strong>
              </v-progress-linear>
              <v-progress-linear
                :value="100"
                color="#d9534f"
                height="20"
                striped
                rounded
                v-if="log.action == 'Pausado'"
              >
                <strong class="label_progress" style="color: #fff">{{
                  log.action
                }}</strong>
              </v-progress-linear>
              <v-progress-linear
                :value="100"
                color="#0275d8"
                height="20"
                striped
                rounded
                v-if="log.action == 'Finalizado'"
              >
                <strong class="label_progress" style="color: #fff">{{
                  log.action
                }}</strong>
              </v-progress-linear>
            </v-col>
          </v-row>
        </v-card-text>
        <!-- <v-card-actions>
          <v-row justify="center" class="ma-2">
            <v-btn
              color="blue darken-1"
              class="ma-2 white--text"
              :loading="loadingSave"
              :disabled="loadingSave || !factory || !machine"
              @click="sendProduction()"
            >
              ENVIAR PARA PRODUÇÃO
            </v-btn>
          </v-row>
        </v-card-actions> -->
      </v-card>
    </v-dialog>
  </div>
</template>

<style lang="scss" scoped>
.card_machine {
  .header {
    background-color: #254b6a;
    color: #fff;
    #btn-edit {
      background-color: #5cb85c;
      border-radius: 4px;
      height: 25px;
      transition: all .3s;
      width: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover {
        background-color: #438343;
      }
    }
  }

  .label_progress {
    color: #fff;
    font-size: 12px;
  }

  .line:nth-child(odd) {
    background-color: #f8f8f8;
  }
}

.text_nenhum {
  font-style: italic;
  margin-top: 30px;
  margin-bottom: 30px;
}

.v-date-picker-title__date {
  font-size: 34px;
  text-align: left;
  font-weight: 500;
  position: relative;
  overflow: hidden;
  padding-bottom: 10px;
  margin-bottom: -8px;
  padding-top: 10px;
}
</style>

<script>
import axios from "axios";
import moment from "moment";
import { format, parseISO } from "date-fns";

export default {
  props: ["items", "url", "token", "user", "dateFiltering"],
  data: function () {
    return {
      filename: "",
      loadingSave: false,
      header: [
        // { text: "#", align: "left", value: "id", },
        { text: "Nr. Docto", align: "left", value: "nr_docto" },
        { text: "Prev. Entrega", align: "left", value: "prev_entrega" },
        // { text: 'Código', align: "left", value: 'cod_produto' },
        { text: "Produto", align: "left", value: "descricao_produto" },
        { text: "Status", align: "left", value: "status" },
        { text: "Nr. Ordem", align: "left", value: "nr_ordem" },
        // { text: "Ações", align: "left", sortable: false, value: "acao", },
      ],
      items_per_page: 15,
      items_per_page2: 1000,
      search: "",
      dialogLoading: false,
      selectedOrders: [],
      dialogDetails: false,
      factory: "",
      factories: [],
      loadingFactories: false,
      machine: "",
      machines: [],
      loadingMachines: false,
      listStatus: [
        { value: 1, text: "Aguardando" },
        { value: 2, text: "Planejada" },
      ],
      status: 1,
      // date: moment().format('dddd, DD MMMM YYYY'),
      date: format(parseISO(new Date().toISOString()), "yyyy-MM-dd"),
      menu1: false,
      details: "",
      initDate: false,
      dateFilter: moment(this.date).format("YYYY-MM-DD"),
      itemsList: [],
      deleteItems: [],
    };
  },

  created() {
    console.log("this.items");
    console.log(this.items);
    console.log(moment().format("YYYY-MM-DD HH:mm:ss"));
    this.itemsList = JSON.parse(JSON.stringify(this.items));
    this.itemsList.forEach((value) => {
      value.orders.forEach((order) => {
        order.updated = moment(
          order.updated_at,
          "YYYY-MM-DD HH:mm:ss"
        ).fromNow();
      });
    });
    this.getFactories();
    if (this.dateFiltering) {
      this.date = moment(this.dateFiltering).format("YYYY-MM-DD");
      this.dateFilter = moment(this.dateFiltering).format("YYYY-MM-DD");
    }
  },

  computed: {
    filteredItems() {
      return this.itemsList.filter((item) => {
        return (
          (!this.factory || item.factories.includes(this.factory)) &&
          (!this.machine || item.id == this.machine)
        );
      });
    },
    computedDateFormattedMomentjs() {
      if (this.date && this.initDate) {
        this.dateFilter = moment(this.date).format("YYYY-MM-DD");
        setTimeout(function () {
          $("#formDate").submit();
        }, 100);
      }
      this.initDate = true;
      return this.date ? moment(this.date).format("dddd, DD MMMM YYYY") : "";
    },
  },

  methods: {
    confirmDelete: function (item) {
      this.$swal({
        title: "Atenção!",
        text: "Deseja realmente excluir os registros selecionados?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#008d4c",
        cancelButtonColor: "#d4d4d4",
        confirmButtonText: "Sim, deletar!",
        cancelButtonText: "Não!",
      }).then((result) => {
        if (result.value != undefined) {
          console.log(item.orders[0]);
          window.location.replace(
            this.url + "/" + this.actionUrl("{item}/destroy", item.orders[0].id)
          );
        }
      });
    },

    showEdit: function (item) {
      let urlItem = '';
      for (let x = 0; x < item.orders.length; x++) {
        if (x == 0) {
          urlItem = item.orders[x].id;
        } else {
          urlItem = urlItem + '!' + item.orders[x].id;
        }
      }
      console.log(item);
      window.location.replace(
        this.url + "/" + this.actionUrl("{item}/reorder", (item.id + '!' +urlItem))
      );
    },

    showDialogDetails(item) {
      this.dialogDetails = true;
      this.details = item;
    },

    getFactories() {
      this.loadingFactories = true;
      this.factories = [];
      axios
        .get("/api/factories")
        .then((response) => {
          console.log("getFactories");
          console.log(response.data);
          this.factories = response.data;
          this.loadingFactories = false;
        })
        .catch((err) => {
          console.error("Ocorreu um erro ao buscar as fábricas.");
          this.loadingFactories = false;
          console.log(err);
        });
    },

    getMachines() {
      this.loadingMachines = true;
      this.machines = [];
      axios
        .get("/api/machines/factory/" + this.factory)
        .then((response) => {
          console.log("getMachines");
          console.log(response.data);
          this.machines = response.data;
          this.loadingMachines = false;
        })
        .catch((err) => {
          console.error("Ocorreu um erro ao buscar as máquinas.");
          this.loadingMachines = false;
          console.log(err);
        });
    },

    reset() {
      this.selectedOrders = [];
      this.factory = "";
      this.machine = "";
    },
    actionUrl: (path, id) => {
      return path.replace(/{item}/g, id);
    },
  },
};
</script>
