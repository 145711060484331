<template>
  <div class="content">
    <div class="spinner" v-if="loading"></div>
    <div class="chart" v-if="!loading">
      <div class="title">
        <span>Utilização</span>
      </div>
      <div class="info-area">
        <radial-progress-bar
          :diameter="diameter"
          :completed-steps="today > 100 ? 100 : today"
          :total-steps="100"
          :strokeWidth="10"
          :innerStrokeWidth="10"
          :start-color="'#67EBB9'"
          :stop-color="'#007D52'"
          :innerStrokeColor="'#ffffff20'"
        >
          <p style="text-align: center">{{ todayText }}%</p>
        </radial-progress-bar>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.content {
  .chart {
    display: block;
    height: 100%;
    width: 100%;
    .title {
      height: 10%;
      width: 100%;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 32px;
    }
    .info-area {
      height: 90%;
      width: 100%;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      p {
        font-size: 20px;
      }
    }
  }
}
@media (max-width: 1440px) {
  .content .chart {
    .title {
      font-size: 24px;
    }
    .info-area {
      font-size: 14px;
      p {
        font-size: 16px;
      }
    }
  }
}
</style>

<script>
import RadialProgressBar from "vue-radial-progress";
import axios from "axios";
export default {
  props: ["date", "machine"],
  data() {
    return {
      loading: true,
      items: 0,
      diameter: 0,
      today: 0,
      averange: 0,
      todayText: 0,
      lastText: 0,
      averangeText: 0,
    };
  },
  methods: {
    getData(spin) {
      spin == true ? this.loading = true : '';
      let data = { date: this.date, machine: this.machine };
      axios
        .post("api/dashboard/get-usage", data)
        .then((response) => {
          this.loading = false;
          this.items = response.data;
          this.refreshGraph();
          this.timeoutAtt();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    refreshGraph() {
      setTimeout(() => {
        let lasttd = this.today == 0 ? 1 : this.today;
        let lastav = this.averange == 0 ? 1 : this.averange;
        this.today = this.items.today;
        this.averange = this.items.averange;

        let stepTime1 = 1000 / (this.today - lasttd);
        let timer1 = setInterval(() => {
          if (this.todayText < this.today) {
            this.todayText += 1;
          }
          if (this.todayText >= this.today) {
            this.todayText = this.items.today;
          }
          if (this.todayText == this.today) {
            clearInterval(timer1);
          }
        }, stepTime1);

        let stepTime2 = 1000 / (this.averange - lastav);
        let timer2 = setInterval(() => {
          if (this.averangeText < this.averange) {
            this.averangeText += 1;
          } else if (this.averangeText >= this.averange) {
            this.averangeText = this.items.averange;
          }
          if (this.averangeText == this.averange) {
            clearInterval(timer2);
          }
        }, stepTime2);
      }, 200);
    },
    timeoutAtt() {
      setTimeout(() => {
        this.getData();
      }, 120000);
    },
  },
  mounted: function () {
    this.getData();
    let screenWidth = screen.width;
    if (screenWidth > 1440) {
      this.diameter = 130;
    } else {
      this.diameter = 100;
    }
  },
  watch: {
    date() {
      this.getData(true);
    },
    machine() {
      this.getData(true);
    },
  },
  components: {
    RadialProgressBar,
  },
};
</script>