<template>
  <div>
    <div class="form-group">
      <label for="factory_id" class="control-label">Unidades Fabris</label>
      <div class="row">
          <div class="col-sm-12">
              <input class="d-none" type="text" id="id_factory" name="factory_id">
              <multiselect
                v-model="inputFactory"
                tagPlaceholder="Adicione uma unidade"
                placeholder="Busque por uma unidade"
                label="name"
                track-by="id"
                :options="factories"
                :multiple="true"
                selectLabel="Precione Enter para selecionar"
                deselectLabel="Precione Enter para remover"
                @input="selectorFactory"
              ></multiselect>
          </div>
      </div>
    </div>
    <div class="form-group">
      <label for="factory_id" class="control-label">Máquinas</label>
      <div class="row">
          <div class="col-sm-12">
              <input type="hidden" id="id_machine" name="machine_id">
              <multiselect
                v-model="inputMachine"
                tagPlaceholder="Adicione uma máquina"
                placeholder="Busque por uma máquina"
                label="name"
                track-by="id"
                :options="machines"
                :multiple="true"
                selectLabel="Precione Enter para selecionar"
                deselectLabel="Precione Enter para remover"
                @input="selectorMachine"
                noOptions="Nenhuma máquina na lista"
              ></multiselect>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: [
    "name",
    "factories",
    "placeholder",
    "multiple",
    "tagplacehold",
    "selectedFactory",
    "selectedMachine",
  ],
  data() {
    return {
      machines: [],
      loadingMachines: false,
      inputFactory: [],
      inputMachine: []
    };
  },
  methods: {
    selectorFactory() {
      document.getElementById('id_factory').value = JSON.stringify(
        this.inputFactory
      );
      if (this.inputFactory.length > 0){
        this.getMachines();
        if (this.inputMachine){
          var ids = [];
          this.inputFactory.forEach(fac => {
            ids.push(fac.id);
          });
          this.inputMachine = this.inputMachine.filter(item => {
            return ids.includes(item.factory_id)
          });
        }
      } else {
        this.machines = [];
        this.inputMachine = [];
        document.getElementById('id_machine').value = '';
      }
    },
    selectorMachine() {
      document.getElementById('id_machine').value = JSON.stringify(
        this.inputMachine
      );
    },
    getMachines(){
      this.loadingMachines = true;
      this.machines = [];
      var dados = [];
      this.inputFactory.forEach(value => {
        dados.push(value.id);
      });
      axios
        .post('/api/machines/factory', dados)
        .then((response) => {
          console.log("getMachines");
          console.log(response.data);
          this.machines = response.data;
          this.loadingMachines = false;
        })
        .catch(err => {
          console.error("Ocorreu um erro ao buscar as máquinas.");
          this.loadingMachines = false;
          console.log(err)
        })
    },
  },
  mounted() {
    if (this.selectedFactory){
      this.inputFactory = this.selectedFactory;
      this.getMachines();
    }
    this.inputMachine = this.selectedMachine;
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
