<template>
  <div class="content">
    <button id="btn-save" @click="save()">Salvar</button>
    <button id="btn-cancel" @click="cancel()">Cancelar</button>
    <draggable v-model="draggableArray" ghost-class="ghost" @end="onEnd">
      <transition-group type="transition" name="flip-list">
        <v-row
          class="sortable card_machine"
          :id="element.id"
          v-for="element in draggableArray"
          :key="element.id"
        >
        <v-col
          cols="12"
          sm="12"
          md="1"
          class="d-flex align-center pl-1"
        >
          {{ element.nr_ordem }}
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="11"
          class="d-flex align-center pl-1"
        >
          {{ element.descricao_produto }}
        </v-col>
        </v-row>
      </transition-group>
    </draggable>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import axios from "axios";

export default {
  props: ["url", "token", "items"],
  components: {
    draggable,
  },
  data: function () {
    return {
      draggableArray: [],
      itemsOrder: [],
      oldIndex: "",
      newIndex: "",
    };
  },
  mounted: function () {
    this.draggableArray = this.items;
    console.log(this.draggableArray);
    for (let i = 0; i < this.draggableArray.length; i++) {
      this.itemsOrder[i] = this.draggableArray[i].id;
    }
  },
  methods: {
    onEnd: function (e) {
      console.log(this.itemsOrder);
      this.oldIndex = e.oldIndex;
      this.newIndex = e.newIndex;
      var guard = this.itemsOrder[this.oldIndex];
      if (this.newIndex > this.oldIndex) {
        for (var x = this.oldIndex; x < this.newIndex; x ++) {
          this.itemsOrder[x] = this.itemsOrder[x+1];
        }
      } else if (this.newIndex < this.oldIndex) {
        for (var x = this.oldIndex; x > this.newIndex; x --) {
          this.itemsOrder[x] = this.itemsOrder[x-1];
        }
      }
      this.itemsOrder[this.newIndex] = guard;
      console.log(this.itemsOrder);
    },
    save: function () {
      var arrstr = JSON.stringify(this.itemsOrder);
      // window.location.replace(
      //   this.url + "/" + this.actionUrl("{item}/orderUpdate", arrstr)
      // );
      axios({
        method: 'post',
        url: '/api/order/update-order',
        data: {
          new: arrstr
        }
      }).then(() => {
        let redirect = this.url.split('programming');
        window.location.replace(redirect[0] + 'programming');
      });
    },
    cancel: function() {
      let redirect = this.url.split('programming');
      window.location.replace(redirect[0] + 'programming');
    },
    actionUrl: (path, id) => {
      return path.replace(/{item}/g, id);
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  width: 100%;
  padding: 10px 10%;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-align: center;
  button {
    border-radius: 4px;
    color: #fff;
    margin-bottom: 20px;
    padding: 7px 14px;
    transition: all 0.3s;

  }
  #btn-save {
    background-color: #5cb85c;
    &:hover {
      background-color: #438343;
    }
  }
  #btn-cancel {
    background-color: #BD2130;
    &:hover {
      background-color: #821621;
    }
  }
  .sortable-drag {
    opacity: 0;
  }
  .flip-list-move {
    transition: transform 0.5s;
  }
  .ghost {
    border-left: 6px solid #132737;
    box-shadow: 8px 8px 5px -1px rgba($color: #000000, $alpha: 0.5);
  }
  .card_machine {
    background-color: #254b6a;
    color: #fff;
    padding: 14px 24px;
    border-radius: 4px;
    margin-bottom: 10px;
    text-align: left;
    width: 100%;
    &:last-child {
      margin-bottom: 0;
    }
    &:hover {
      cursor: move;
    }
  }
}
</style>