<template>
  <div class="content">
    <div class="spinner" v-if="loading"></div>
    <div class="chart" v-if="!loading">
      <div class="title">
        <span>capacidade</span>
      </div>
      <div class="info-area">
        <div class="info-graph">
          <radial-progress-bar
            :diameter="diameter"
            :completed-steps="percentage > 100 ? 100 : percentage"
            :total-steps="100"
            :strokeWidth="10"
            :innerStrokeWidth="10"
            :start-color="'#67EBB9'"
            :stop-color="'#007D52'"
            :innerStrokeColor="'#ffffff20'"
          >
            <p style="text-align: center">{{ percentageText }}%</p>
          </radial-progress-bar>
        </div>
        <div class="info-text">
          <p>
            <span>Utilizada</span>
            <span>{{ items.usedCapacity }}m</span>
          </p>
          <p>
            <span>Total</span>
            <span>{{ items.totalCapacity }}m</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.content {
  .chart {
    display: block;
    height: 100%;
    width: 100%;
    .title {
      height: 10%;
      width: 100%;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 32px;
    }
    .info-area {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      margin: auto;
      .info-graph {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
      }
      .info-text {
        width: 50%;
        p {
          width: 100%;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 2%;
          &:first-child {
            margin-bottom: 5%;
          }
          span {
            width: 50%;
            padding-left: 4%;
            color: #67ebb9;
            &:first-child {
              text-align: right;
              padding-left: 0;
              padding-right: 4%;
              border-right: 2px solid rgba(255, 255, 255, 1);
              color: #fff;
              font-weight: 200;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 1440px) {
  .content .chart {
    .title {
      font-size: 24px;
    }
    .info-text p,
    .info-graph p {
      font-size: 16px;
      span {
        font-size: 14px;
      }
    }
  }
}
</style>

<script>
import RadialProgressBar from "vue-radial-progress";
import axios from "axios";
export default {
  props: ["date", "machine"],
  data() {
    return {
      loading: true,
      items: 0,
      diameter: 0,
      percentage: 0,
      percentageText: 0,
      monthPercentage: 0,
      monthPercentageText: 0,
    };
  },
  methods: {
    getData(spin) {
      spin == true ? (this.loading = true) : "";
      let data = { date: this.date, machine: this.machine };
      axios
        .post("api/dashboard/get-capacity-usage", data)
        .then((response) => {
          this.loading = false;
          this.items = response.data;
          this.refreshGraph();
          this.timeoutAtt();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    refreshGraph() {
      setTimeout(() => {
        this.percentage =
          this.items.usedPercentage > 100 ? 100 : this.items.usedPercentage;
        let range = this.items.usedPercentage;

        let stepTime = 1000 / range;
        let timer = setInterval(() => {
          if (this.percentageText < this.items.usedPercentage) {
            this.percentageText += 1;
          } else if (this.percentageText >= this.items.usedPercentage) {
            this.percentageText = this.items.usedPercentage;
          }
          if (this.percentageText == this.items.usedPercentage) {
            clearInterval(timer);
          }
        }, stepTime);
      }, 200);
    },
    timeoutAtt() {
      setTimeout(() => {
        this.getData();
      }, 120000);
    },
  },
  mounted: function () {
    this.getData();
    let screenWidth = screen.width;
    if (screenWidth > 1440) {
      this.diameter = 130;
    } else {
      this.diameter = 100;
    }
  },
  watch: {
    date() {
      this.getData(true);
    },
    machine() {
      this.getData(true);
    },
  },
  components: {
    RadialProgressBar,
  },
};
</script>