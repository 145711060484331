<template>
  <transition name="dash-fade" mode="out-in">
    <div class="page" :key="loading">
      <div class="loading" v-if="loading">
        <img src="/img/logodash.png" alt="Logo Brastelha" />
        <div class="spinner"></div>
      </div>

      <div class="dashboard" v-if="!loading">
        <v-overlay
          :absolute="true"
          :opacity="0.6"
          :value="overlay"
          :z-index="9"
          data-app
        >
          <v-select
            :items="items"
            label="Máquina"
            solo
            background-color="#254B6A"
            v-model="select"
          ></v-select>
          <v-date-picker
            no-title
            v-model="picker"
            color="#254B6A"
            type="date"
            width="310"
            class="mt-6"
            locale="pt-br"
            range
          ></v-date-picker><br>
          <v-btn style="width: 100%; margin-top:10%" color="#254B6A" @click="toggleOverlay(false)">Filtrar</v-btn>
          <div class="close-overlay" @click="toggleOverlay(false)">
            <span class="mdi mdi-close"></span>
          </div>
        </v-overlay>
        <div class="grid-container">
          <div class="logo area">
            <v-card>
              <card-logo></card-logo>
            </v-card>
          </div>
          <div class="graph1 area area-hover">
            <v-card>
              <card-usage :date="date" :machine="machine"></card-usage>
            </v-card>
          </div>
          <div class="graph2 area area-hover">
            <v-card>
              <card-performance
                :date="date"
                :machine="machine"
              ></card-performance>
            </v-card>
          </div>
          <div class="graph3 area area-hover">
            <v-card>
              <card-inativity :date="date" :machine="machine"></card-inativity>
            </v-card>
          </div>
          <div class="graph4 area area-hover">
            <v-card>
              <card-productivity
                :date="date"
                :machine="machine"
              ></card-productivity>
            </v-card>
          </div>
          <div class="graph5 area area-hover">
            <v-card>
              <card-capacity-usage
                :date="date"
                :machine="machine"
              ></card-capacity-usage>
            </v-card>
          </div>
          <div class="graph6 area area-hover">
            <v-card>
              <!-- <card-oee class="w-100" :height="280"></card-oee> -->
            </v-card>
          </div>
          <div class="graph7 area area-hover">
            <v-card></v-card>
          </div>
          <div class="graph8 area area-hover">
            <v-card></v-card>
          </div>
          <div class="machines area area-hover">
            <v-card>
              <card-machines :machine="machine"></card-machines>
            </v-card>
          </div>
        </div>
        <div class="filter" @click="toggleOverlay(true)">
          <span class="mdi mdi-cog"></span>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      overlay: false,
      picker: [
        new Date().toISOString().substr(0, 10),
        new Date().toISOString().substr(0, 10),
      ],
      date: [
        new Date().toISOString().substr(0, 10),
        new Date().toISOString().substr(0, 10),
      ],
      items: [],
      loading: true,
      select: 'all',
      machine: "all",
    };
  },
  methods: {
    toggleOverlay(change) {
      this.overlay = change;
      this.date = this.picker;
      this.machine = this.select;
    },
    getData() {
      let data = {
        machine: this.machine,
      };
      axios
        .post("api/dashboard/get-machines", data)
        .then((response) => {
          this.loading = false;
          this.items = response.data;
          let lenght = this.items.length;
          this.items.forEach((element, index) => {
            this.items[index] = element.name;
          });
          this.items[lenght] = "Geral";
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
  mounted: function () {
    this.getData();
  },
};
</script>

<style lang="scss">
.dash-fade-enter-active,
.dash-fade-leave-active {
  transition: all 1s cubic-bezier(0.94, 0.47, 0.53, 1.01);
}
.dash-fade-enter,
.dash-fade-leave-to {
  opacity: 0;
}
.page {
  widows: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .loading {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
      width: 35%;
      margin-bottom: 5%;
    }
    .spinner {
      min-width: 40px;
      min-height: 40px;
      border: 5px solid rgba(255, 255, 255, 0.1);
      border-right: 5px solid #67ebb9;
      border-radius: 50%;
      animation: spinner 1s linear infinite;
    }
  }
  .dashboard {
    width: 100%;
    height: 100%;
    text-transform: uppercase;
    background-color: #111c30;
    .v-input {
      max-width: 310px;
    }
    .filter,
    .close-overlay {
      position: fixed;
      bottom: 20px;
      right: 20px;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background-color: #13284f;
      box-shadow: 0 0 16px 4px #10192b;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.3s;
      span {
        color: #fff;
        font-size: 32px;
      }
      &:hover {
        cursor: pointer;
        background-color: #314d80;
      }
    }
    .content {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
    }
    .grid-container {
      width: 100%;
      height: 100%;
      padding: 28px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr 1fr;
      gap: 10px 10px;
      grid-template-areas:
        "logo graph1 graph2 graph3"
        "graph4 graph5 graph6 machines"
        "graph4 graph7 graph8 machines";
      .area .v-card {
        width: 100%;
        height: 100%;
        background-color: #17253f;
        box-shadow: none;
        border-radius: 6px;
        padding: 10px;
        transition: all 0.5s;
      }
      .logo {
        grid-area: logo;
      }
      .graph1 {
        grid-area: graph1;
      }
      .graph2 {
        grid-area: graph2;
      }
      .graph3 {
        grid-area: graph3;
      }
      .graph4 {
        grid-area: graph4;
      }
      .graph5 {
        grid-area: graph5;
      }
      .graph6 {
        grid-area: graph6;
      }
      .graph7 {
        grid-area: graph7;
      }
      .graph8 {
        grid-area: graph8;
      }
      .machines {
        grid-area: machines;
      }
    }
    .spinner {
      min-width: 40px;
      min-height: 40px;
      border: 5px solid rgba(255, 255, 255, 0.1);
      border-right: 5px solid #67ebb9;
      border-radius: 50%;
      animation: spinner 1s linear infinite;
    }
  }
}

@media (max-width: 1440px) {
  .dashboard {
    .grid-container {
      padding: 14px;
    }
  }
}

@keyframes spinner {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>