/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

window.Popper = require('popper.js');

window.$ = window.jQuery = require('jquery');

require('admin-lte');
require("bootstrap");

window.Vue = require("vue");

import Vue from "vue";

const moment = require('moment')
require('moment/locale/pt-br')
Vue.use(require('vue-moment'), {
  moment
})

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);

import money from "v-money";
Vue.use(money, { precision: 4 });

import VueTheMask from 'vue-the-mask'
Vue.use(VueTheMask)

import VueCarousel from 'vue-carousel';
Vue.use(VueCarousel);

import Multiselect from "vue-multiselect";

import Vuetify from 'vuetify';
Vue.use(Vuetify);

import Vue2Filters from 'vue2-filters'
Vue.use(Vue2Filters)

import "flatpickr";
import "flatpickr/dist/flatpickr.min.css";

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */
Vue.component('multiselect', Multiselect);
Vue.component('multi-select', require("./cms/UIMultiSelect.vue").default);
Vue.component("content-header", require("./cms/ContentHeader.vue").default);
Vue.component("data-table", require("./cms/DataTable.vue").default);
Vue.component("tabs", require("./cms/Tabs.vue").default);
Vue.component("ui-form", require("./cms/UIForm.vue").default);
Vue.component("ui-select", require("./cms/UISelect.vue").default);
Vue.component("ui-textarea", require("./cms/UITextarea.vue").default);
Vue.component("ui-money", require("./cms/UIMoney.vue").default);
Vue.component("ui-phone", require("./cms/UIPhone.vue").default);
Vue.component("ui-mask-input", require("./cms/UIMaskInput.vue").default);
Vue.component("alert", require("./cms/Alert.vue").default);
Vue.component("checkboxes", require("./cms/Checkboxes.vue").default);
Vue.component("radios", require("./cms/Radios.vue").default);
Vue.component("dropdown-list", require("./cms/DropdownList.vue").default);
Vue.component("dropdown-events", require("./cms/DropdownEvents.vue").default);
Vue.component("cidade-bairro", require("./cms/Cidade-bairro.vue").default);
Vue.component("data-table-orders", require("./cms/DataTableOrders.vue").default);
Vue.component("programming", require("./cms/Programming.vue").default);
Vue.component("ordenate", require('./cms/Ordenate.vue').default);
Vue.component("select-factory-machines", require("./cms/SelectFactoryMachines.vue").default);
Vue.component('select-machines', require('./cms/SelectMachines.vue').default);
Vue.component('data-table-pause', require('./cms/DataTablePause.vue').default);
Vue.component('data-table-oees', require('./cms/DataTableOees.vue').default);

Vue.component("the-gallery", require("./front/TheGallery.vue").default);
Vue.component("dashboard", require("./front/Dashboard.vue").default);
Vue.component("dashboard-oees", require("./front/DashboardOees.vue").default);
Vue.component("card-logo", require("./front/CardLogo.vue").default);
Vue.component("card-usage", require("./front/CardUsage.vue").default);
Vue.component("card-performance", require("./front/CardPerformance.vue").default);
Vue.component("card-inativity", require("./front/CardInativity.vue").default);
Vue.component("card-capacity-usage", require("./front/CardCapacityUsage.vue").default);
Vue.component("card-productivity", require("./front/CardProductivity.vue").default);
Vue.component("card-dashboard-oee", require("./front/CardDashboardOee.vue").default);
Vue.component("card-oee", require("./front/CardOEE.vue").default);
Vue.component("card-machines", require("./front/CardMachines.vue").default);

const app = new Vue({
  el: "#app",
  vuetify: new Vuetify(),
});

flatpickr("#inputDate", {
  enableTime: true,
  locale: 'pt',
  maxDate: "today",
  dateFormat: "Y-m-d H:i",
  altFormat: "d/m/Y H:i",
  time_24hr: true,
  disableMobile: true,
});