<template>
  <div class="box box-widget">
    <div class="box-body">
      <slot></slot>
      <form :action="url" method="" id="searchForm">
        <div class="row justify-content-end" style="align-items: baseline">
          <div class="col-12 col-sm-6 col-lg-4">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ attrs }">
                <v-row align="center" justify="end">
                  <v-text-field
                    label="Buscar por período"
                    :value="formatedDate"
                    class="mr-sm-1 mr-3 ml-3 ml-sm-0 width-auto"
                    append-icon="mdi-calendar"
                    ref="date_input"
                    readonly
                    hide-details
                    v-bind="attrs"
                    outlined
                    clearable
                    @click:append="menu = true"
                    @focus="openPicker()"
                    @click="onFocus()"
                    @click:clear="dates = []"
                    ><!-- <template v-slot:append-outer>                          
                        </template> -->
                  </v-text-field>
                </v-row>
              </template>
              <v-date-picker v-model="dates" no-title range @change="order()">
              </v-date-picker>
            </v-menu>
          </div>
          <div class="col-12 col-sm-6 col-lg-4">
            <v-text-field
              outlined
              @click:append-outer="submitSearch()"
              append-outer-icon="mdi-magnify"
              type="text"
              placeholder="Buscar por"
              v-model="busca"              
              name="busca"
              id="busca"
            ></v-text-field>
          </div>
        </div>
      </form>
      <form
        v-if="items.data.length > 0"
        :class="formClass"
        v-on:submit.prevent="confirmDelete"
        :action="url + '/destroy'"
        method="POST"
      >
        <input type="hidden" name="_token" :value="token" />
        <input type="hidden" name="_method" value="DELETE" />
        <table id="example1" class="table">
          <thead class="table-dark">
            <tr>
              <th v-if="!notDeletable" align="center" width="50px">
                <input
                  class="checkbox-delete"
                  id="checkbox-delete-origin"
                  type="checkbox"
                  name="registro[]"
                  data-toggle="tooltip"
                  title="Marcar tudo"
                  v-on:click="checkAll"
                />
              </th>
              <th v-for="(title, index) in titles" :key="index">
                <span v-if="title">{{ title }}</span>
              </th>
              <th v-if="actions != 'false'" style="width: 225px">Ações</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in items.data" :key="index">
              <th v-if="!notDeletable" align="center" class="v-middle">
                <input
                  class="checkbox-delete"
                  type="checkbox"
                  name="registro[]"
                  :value="item.id"
                />
              </th>
              <td v-for="(field, index) in item" :key="index" class="v-middle">
                <span v-if="field === null">{{ field }}</span>

                <span
                  v-else-if="
                    !field.type && !Array.isArray(field) && field == item.id
                  "
                  >{{ item.date.substr(0, 10) }}</span
                >

                <span
                  v-else-if="
                    !field.type && !Array.isArray(field) && field == item.date
                  "
                  >{{ field.substr(11) }}</span
                >

                <span
                  v-else-if="
                    !field.type &&
                    !Array.isArray(field) &&
                    field != item.created_at &&
                    field != item.id
                  "
                  >{{ field }}</span
                >

                <span v-else-if="field.type == 'img'">
                  <img
                    :src="field.src"
                    style="max-width: 150px; max-height: 150px"
                  />
                </span>

                <span
                  v-else-if="field.type == 'label'"
                  class="label"
                  :class="'label-' + field.status"
                  style="color: #fff"
                  >{{ field.text }}</span
                >
                <span
                  class="mr-2 px-2 py-0 rounded-pill badge"
                  v-else-if="isMultiBadge(field)"
                  v-for="(campo, index) in field"
                  :key="index"
                  :class="'badge-' + campo.status"
                  style="color: #fff"
                >
                  <span>{{ campo.text }}</span>
                </span>
                <span
                  v-else-if="field.type == 'badge'"
                  class="badge"
                  :class="'badge-' + field.status"
                  style="color: #fff"
                  >{{ field.text }}</span
                >
              </td>
              <td v-if="!actions && actions != 'false'" class="v-middle">
                <a
                  data-toggle="tooltip"
                  class="badge ml-10 badge-primary"
                  :href="url + '/' + item.id + '/edit'"
                  title="Editar"
                  style="color: #fff"
                >
                  <span>Editar</span> </a
                >&nbsp;
              </td>
              <td class="v-middle" v-if="actions && actions != 'false'">
                <span v-for="(action, index) in actions" :key="index">
                  <span v-if="action.label == 'Desativar'">
                    <a
                      v-if="item.status['text'] != 'Inativo'"
                      data-toggle="tooltip"
                      class="badge mb-1"
                      :class="'badge-' + action.color"
                      :href="url + '/' + actionUrl(action.path, item.id)"
                      :title="action.label"
                      style="color: #fff"
                    >
                      <span>{{ action.icon }}</span>
                    </a>
                    <a
                      v-if="item.status['text'] == 'Inativo'"
                      data-toggle="tooltip"
                      class="badge mb-1"
                      :class="'badge-' + action.color"
                      :href="url + '/' + actionUrl('{item}/enable', item.id)"
                      title="Ativar"
                      style="color: #fff"
                    >
                      <span>Ativar</span> </a
                    >&nbsp;
                  </span>
                  <span v-else-if="action.label == 'Excluir'">
                    <a
                      data-toggle="tooltip"
                      class="badge mb-1"
                      :class="'badge-' + action.color"
                      @click="confirmDelete(item)"
                      :title="action.label"
                      style="color: #fff"
                    >
                      <span>{{ action.icon }}</span> </a
                    >&nbsp;
                  </span>
                  <span v-else>
                    <a
                      data-toggle="tooltip"
                      class="badge btn-sm mb-1"
                      :class="'badge-' + action.color"
                      :title="action.label"
                      :href="url + '/' + actionUrl(action.path, item.id)"
                      style="color: #fff"
                    >
                      <span>{{ action.icon }}</span> </a
                    >&nbsp;
                  </span>
                </span>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td
                :colspan="titles.length + 2"
                align="left"
                vertical-align="center"
              >
                <button
                  v-if="!notDeletable"
                  type="submit"
                  class="mv-22 btn btn-flat btn-danger"
                  :disabled="btnDelete.disabled"
                >
                  <i :class="btnDelete.icon"></i>
                  {{ btnDelete.text }}
                </button>
                <slot name="pagination"></slot>
              </td>
            </tr>
          </tfoot>
        </table>
      </form>

      <div v-if="items.data.length == 0" class="callout callout-default">
        <h4>Nenhum registro encontrado!</h4>
      </div>
    </div>
  </div>
</template>

<style>
.mv-22 {
  margin: 22px 0;
}

.v-middle {
  vertical-align: middle !important;
}
</style>

<script>
export default {
  props: [
    "title",
    "titles",
    "items",
    "busca",
    "url",
    "token",
    "formClass",
    "actions",
    "date_range"
  ],
  data: function () {
    return {
      notDeletable: false,
      deleteItems: [],
      menu: false,
      count: 0,
      dates: [],
      btnDelete: {
        icon: "fa fa-trash",
        text: "Excluir registros selecionados",
        disabled: false,
      },
    };
  },
  created() {
    console.log(JSON.parse(this.date_range));
    if (JSON.parse(this.date_range).length > 0) {
      this.dates[0] = JSON.parse(this.date_range)[0].substring(0,10);
      this.dates[1] = JSON.parse(this.date_range)[1].substring(0,10);
    }
    if (!this.items.data) {
      this.items.data = this.items;
    }
  },

  computed: {
    formatedDate() {
      if (this.dates.length > 0) {
        if (this.dates.length == 1) {
          return (
            this.convertDate(this.dates[0]) +
            " até " +
            this.convertDate(this.dates[0])
          );
        } else {
          return (
            this.convertDate(this.dates[0]) +
            " até " +
            this.convertDate(this.dates[1])
          );
        }
      }
    },
  },

  methods: {
    convertDate(date) {
      return (
        date.substring(8, 10) +
        "/" +
        date.substring(5, 7) +
        "/" +
        date.substring(0, 4)
      );
    },
    isMultiBadge(field) {
      if (Array.isArray(field) && field.length > 0) {
        if (field[0].type == "multiBadge") {
          return true;
        }
      }
      return false;
    },
    checkAll: function () {
      var checkboxOrigin = document.getElementById("checkbox-delete-origin");
      var checkboxes = document.getElementsByClassName("checkbox-delete");
      for (var i = 0, n = checkboxes.length; i < n; i++) {
        checkboxes[i].checked = checkboxOrigin.checked;
      }
    },
    confirmDelete: function (item) {
      this.$swal({
        title: "Atenção!",
        text: "Deseja realmente excluir os registros selecionados?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#008d4c",
        cancelButtonColor: "#d4d4d4",
        confirmButtonText: "Sim, deletar!",
        cancelButtonText: "Não!",
      }).then((result) => {
        if (result.value != undefined) {
          if (item.id) {
            window.location.replace(
              this.url +
                "/" +
                this.actionUrl("{item}/destroy", JSON.stringify(item.id))
            );
          } else {
            var checkboxOrigin = document.getElementById(
              "checkbox-delete-origin"
            );
            var checkboxes = document.getElementsByClassName("checkbox-delete");
            for (var i = 0, n = checkboxes.length; i < n; i++) {
              if (checkboxes[i].checked == true) {
                this.deleteItems.push(checkboxes[i].value);
              }
            }
            window.location.replace(
              this.url +
                "/" +
                this.actionUrl(
                  "{item}/destroy",
                  JSON.stringify(this.deleteItems)
                )
            );
            this.btnDelete.icon = "fa fa-spinner fa-pulse";
            this.btnDelete.text = "Excluindo";
            this.btnDelete.disabled = true;
          }
        }
      });
    },
    actionUrl: (path, id) => {
      return path.replace(/{item}/g, id);
    },

    submitSearch() {
      // document.getElementById("searchForm").submit();
      if (this.dates.length <= 0) {
        this.dates[0]='';
        this.dates[1]='';
      } else if (!this.dates[1]) {
        this.dates[1] = this.dates[0];
      }
      location.href =
        location.pathname +
        "?busca=" +
        this.busca +
        "&firstDate=" +
        this.dates[0] +
        "&endDate=" +
        this.dates[1];
    },

    openPicker() {
      if (this.count == 0) {
        setTimeout(() => {
          this.menu = true;
        }, 100);
        this.count += 1;
      } else {
        this.menu = true;
      }
      console.log(this.menu);
    },

    onFocus() {
      var div = this.$refs.date_input.$el;
      var input = div.getElementsByTagName("input")[0];
      if (input.ariaExpanded == "false") {
        document.activeElement.blur();
      }
    },

    order() {
      this.dates.sort(function (inicial, final) {
        let a = new Date(inicial),
          b = new Date(final);
        return a - b;
      });
    },
  },
};
</script>
