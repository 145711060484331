<template>
  <transition name="dash-fade" mode="out-in">
    <div data-app class="h-100" :key="loading">
      <div class="loading" v-if="loading">
        <img src="/img/logodash.png" alt="Logo Brastelha" />
        <div class="spinner"></div>
      </div>
      <div class="h-100" v-if="!loading">
        <div class="h-100 no-have" v-if="oees_all.length <= 0 || !oees_all">
          <span v-if="filtered"
            >Não foram encontrados dados para este filtro.</span
          >
          <span v-else-if="!filtered">
            Não foram encontrados dados até o momento.
          </span>
        </div>
        <!-- FULL -->
        <div
          class="row h-100"
          style="padding-right: 15px"
          v-if="oees_all.length < 5 && oees_all.length > 0"
        >
          <div
            v-for="(oee, index) in oees_all"
            :key="index"
            class="cards-group"
            :class="
              oees_all.length >= 4
                ? 'col-3'
                : oees_all.length == 3
                ? 'col-4'
                : oees_all.length == 2
                ? 'col-6'
                : oees_all.length == 1
                ? 'col-12'
                : ''
            "
            :style="
              index == 0
                ? 'padding-left:25px'
                : index == 5
                ? 'padding-right:25px'
                : ''
            "
          >
            <div class="manual-card" style="position: relative; height: 100vh">
              <div
                class="card-title d-flex justify-content-center"
                style="height: 3vh; align-items: center"
              >
                <span>{{ oee[0].name }}</span>
              </div>
              <div class="row" style="height: 10vh; align-items: center">
                <!-- <div style="text-align: center" class="col-3">ID</div> -->
                <div style="text-align: center" class="col-4">Horário</div>
                <div style="text-align: center" class="col-4">Qtd.</div>
                <div style="text-align: center" class="col-4">Tam.</div>
              </div>
              <div
                :id="'oee-card-body' + index"
                style="height: calc(100% - 100px)"
              >
                <div class="content-scrollbar" style="height: 77vh">
                  <div v-for="(item, index2) in oee" :key="index2" class="row">
                    <!-- <div style="text-align: center" class="col-4">
                      {{ item.id }}
                    </div> -->
                    <div style="text-align: center" class="col-4">
                      {{ item.time }}
                    </div>
                    <div style="text-align: center" class="col-4">
                      {{ item.amount }}
                    </div>
                    <div style="text-align: center" class="col-4">
                      {{ item.size }}
                    </div>
                  </div>
                </div>
              </div>
              <div
                style="
                  position: absolute;
                  bottom: 4vh;
                  display: flex;
                  flex-direction: column;
                "
              >
                <span>Quantidade Total: {{ oee[0].amount }}</span>
                <span>Tamanho Total: {{ oee[0].total_size.toFixed(2) }} m</span>
              </div>
            </div>
          </div>
        </div>
        <!-- HALF/HALF -->
        <carousel
          :scrollPerPage="false"
          :autoplayTimeout="4500"
          :autoplayHoverPause="true"
          :perPage="first_half.length >= 4 ? 4 : first_half.length"
          :autoplay="true"
          :loop="true"
          class="row h-50"
          style="padding-right: 15px; margin-top: -10px; padding-left: 25px"
          v-if="oees_all.length > 4"
        >
          <slide
            v-for="(oee, index) in first_half"
            :key="index"
            class="cards-group-f"
            :class="
              first_half.length >= 4
                ? 'col-3'
                : first_half.length == 3
                ? 'col-4'
                : first_half.length == 2
                ? 'col-6'
                : first_half.length == 1
                ? 'cols-12'
                : ''
            "
          >
            <!-- :style="
          index == 0
            ? 'padding-left:25px'
            : index == 5
            ? 'padding-right:25px'
            : ''
        " -->
            <div class="manual-card" style="position: relative; height: 48vh">
              <div
                class="card-title d-flex justify-content-center"
                style="height: 3vh; align-items: center"
              >
                <span>{{ oee[0].name }}</span>
              </div>
              <div class="row" style="height: 10vh; align-items: center">
                <!-- <div style="text-align: center" class="col-3">ID</div> -->
                <div style="text-align: center" class="col-4">Horário</div>
                <div style="text-align: center" class="col-4">Qtd.</div>
                <div style="text-align: center" class="col-4">Tam.</div>
              </div>
              <div :id="'oee-card-body' + index">
                <div class="content-scrollbar" style="height: 28vh">
                  <div v-for="(item, index2) in oee" :key="index2" class="row">
                    <!-- <div style="text-align: center" class="col-4">
                      {{ item.id }}
                    </div> -->
                    <div style="text-align: center" class="col-4">
                      {{ item.time }}
                    </div>
                    <div style="text-align: center" class="col-4">
                      {{ item.amount }}
                    </div>
                    <div style="text-align: center" class="col-4">
                      {{ item.size }}
                    </div>
                  </div>
                </div>
              </div>
              <div
                style="
                  position: absolute;
                  bottom: 2vh;
                  display: flex;
                  flex-direction: column;
                "
              >
                <span>Quantidade Total: {{ oee[0].amount }}</span>
                <span>Tamanho Total: {{ oee[0].total_size.toFixed(2) }} m</span>
              </div>
            </div>
          </slide>
        </carousel>
        <carousel
          :scrollPerPage="false"
          :autoplayTimeout="4500"
          :autoplayHoverPause="true"
          :perPage="last_half.length >= 4 ? 4 : last_half.length"
          :autoplay="true"
          :loop="true"
          class="row h-50"
          style="padding-top: 0; padding-right: 15px; padding-left: 25px"
          v-if="oees_all.length > 4"
        >
          <slide
            v-for="(oee, index) in last_half"
            :key="index"
            class="cards-group-l"
            :class="
              last_half.length >= 4
                ? 'col-3'
                : last_half.length == 3
                ? 'col-4'
                : last_half.length == 2
                ? 'col-6'
                : last_half.length == 1
                ? 'cols-12'
                : ''
            "
          >
            <!-- :style="
          index == 0
            ? 'padding-left:25px'
            : index == 5
            ? 'padding-right:25px'
            : ''
        " -->
            <div class="manual-card" style="position: relative; height: 48vh">
              <div
                class="card-title d-flex justify-content-center"
                style="height: 3vh; align-items: center"
              >
                <span>{{ oee[0].name }}</span>
              </div>
              <div class="row" style="height: 10vh; align-items: center">
                <!-- <div style="text-align: center" class="col-3">ID</div> -->
                <div style="text-align: center" class="col-4">Horário</div>
                <div style="text-align: center" class="col-4">Qtd.</div>
                <div style="text-align: center" class="col-4">Tam.</div>
              </div>
              <div :id="'oee-card-body' + index">
                <div class="content-scrollbar" style="height: 28vh">
                  <div v-for="(item, index2) in oee" :key="index2" class="row">
                    <!-- <div style="text-align: center" class="col-4">
                      {{ item.id }}
                    </div> -->
                    <div style="text-align: center" class="col-4">
                      {{ item.time }}
                    </div>
                    <div style="text-align: center" class="col-4">
                      {{ item.amount }}
                    </div>
                    <div style="text-align: center" class="col-4">
                      {{ item.size }}
                    </div>
                  </div>
                </div>
              </div>
              <div
                style="
                  position: absolute;
                  bottom: 2vh;
                  display: flex;
                  flex-direction: column;
                "
              >
                <span>Quantidade Total: {{ oee[0].amount }}</span>
                <span>Tamanho Total: {{ oee[0].total_size.toFixed(2) }} m</span>
              </div>
            </div>
          </slide>
        </carousel>
        <v-btn
          elevation="2"
          fab
          right
          bottom
          color="#13284f"
          fixed
          @click="openOverlay()"
        >
          <v-icon style="color: #fff">mdi-cog</v-icon>
        </v-btn>
        <v-overlay
          opacity="0.95"
          color="#232f43"
          :z-index="9999"
          :value="overlay"
        >
          <v-btn
            elevation="2"
            fab
            right
            bottom
            color="#13284f"
            fixed
            @click="closeOverlay()"
          >
            <v-icon style="color: #fff">mdi-close</v-icon>
          </v-btn>
          <div style="width: 310px">
            <v-select
              background-color="#042b3d"
              v-model="value_selected"
              item-value="id"
              item-text="name"
              :items="items_select"
              label="Selecione as maquinas"
              multiple
            >
              <template v-slot:selection="{ item, index }">
                <v-chip v-if="index === 0">
                  <span>{{ item.name }}</span>
                </v-chip>
                <v-chip v-if="index === 1">
                  <span>{{ item.name }}</span>
                </v-chip>
                <v-chip v-if="index === 2">
                  <span>{{ item.name }}</span>
                </v-chip>
                <span v-if="index === 3" class="grey--text text-caption">
                  (+{{ value_selected.length - 3 }} outros)
                </span>
              </template>
            </v-select>
          </div>
          <div>
            <v-date-picker
              v-model="dates"
              range
              selected-items-text="2 Selecionadas"
              locale="pt-br"
              width="310"
              :max="nowDate"
            ></v-date-picker>
            <v-row style="align-content: center">
              <v-col style="padding-right: 0" cols="6"
                ><v-btn
                  @click="clearFilter()"
                  width="100%"
                  style="background-color: #d3312ecc; border-radius: 0 0 0 4px"
                  >Limpar Filtro</v-btn
                ></v-col
              >
              <v-col style="padding-left: 0" cols="6"
                ><v-btn
                  width="100%"
                  style="background-color: #039003; border-radius: 0 0 4px 0"
                  @click="Filter()"
                  >Filtrar</v-btn
                ></v-col
              >
            </v-row>
          </div>
        </v-overlay>
      </div>
    </div>
  </transition>
</template>

<script>
import Axios from "axios";
export default {
  props: ["oees_list", "date_atm", "items_select"],
  data() {
    return {
      dynamic_height: 0,
      first_half: [],
      last_half: [],
      overlay: false,
      nowDate: new Date().toISOString().slice(0, 10),
      dates: [],
      oees_all: [],
      value_selected: [],
      filtered: false,
      loading: true,
      interval: false
    };
  },

  created() {
    this.oees_all = this.oees_list;
    if (this.oees_all.length >= 5) {
      this.first_half = this.oees_all.slice(0, this.oees_all.length / 2);
      this.last_half = this.oees_all.slice(this.first_half.length);
    }
    this.dates = this.date_atm;
    this.Repeat();
  },

  mounted() {
    this.loading = false;
  },

  methods: {
    Filter(repeater = false) {
      clearInterval(this.interval);
      if (repeater == false){
        this.loading = true;
      }
      this.filtered = true;
      if (!this.dates[1]) {
        this.dates[1] = this.dates[0];
      }
      this.dates.sort(function (inicial, final) {
        let a = new Date(inicial),
          b = new Date(final);
        return a - b;
      });
      Axios.get(
        "/api/view-oee?filter=1&startdate=" +
          this.dates[0] +
          "&enddate=" +
          this.dates[1] +
          "&machines=" +
          JSON.stringify(this.value_selected)
      )
        .then((response) => {
          this.dates = response.data.dates;

          this.oees_all = response.data.oees;
          if (this.oees_all.length >= 5) {
            this.first_half = this.oees_all.slice(0, this.oees_all.length / 2);
            this.last_half = this.oees_all.slice(this.first_half.length);
          }
          this.overlay = false;
          this.loading = false;
          this.Repeat();
        })
        .catch((e) => {
          this.loading = false;
          console.error(e);
        });
    },

    clearFilter() {
      clearInterval(this.interval);
      this.loading = true;
      this.value_selected = [];
      this.filtered = false;
      Axios.get(
        "/api/view-oee?filter=1&startdate=" +
          this.date_atm[0] +
          "&enddate=" +
          this.date_atm[1] +
          "&machines=" +
          JSON.stringify(this.value_selected)
      )
        .then((response) => {
          this.dates = response.data.dates;

          this.oees_all = response.data.oees;
          if (this.oees_all.length >= 5) {
            this.first_half = this.oees_all.slice(0, this.oees_all.length / 2);
            this.last_half = this.oees_all.slice(this.first_half.length);
          }
          this.overlay = false;
          this.loading = false;
          this.Repeat();
        })
        .catch((e) => {
          this.loading = false;
        });
    },

    Repeat() {
      clearInterval(this.interval);
      this.interval = setInterval(() => {
        this.Filter(true);
      }, 5000);
    },

    openOverlay(){
      clearInterval(this.interval);
      this.overlay = true;
    },

    closeOverlay(){
      this.overlay = false;
      this.Repeat();
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-date-picker-table .v-btn.v-btn--active {
  background-color: #1f3b70;
}

::v-deep .v-picker__title {
  background-color: #111c30 !important;
}

::v-deep .v-btn--absolute {
  bottom: 10px !important;
}

.card-col-contet {
  text-align: center;
  color: white;
}

.card-title {
  text-transform: uppercase;
  margin: 0;
}

.manual-card {
  background-color: #17253f;
  color: white;
  padding: 12px;
}

.cards-group {
  padding: 25px 10px 0 0;
}

.cards-group-f {
  padding: 25px 10px 0 0;
}

.cards-group-l {
  padding: 15px 10px 0 0;
}
.content-scrollbar {
  overflow-y: auto;
  overflow-x: hidden;
}

.content-scrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #17253f;
}

.content-scrollbar::-webkit-scrollbar {
  width: 6px;
  background-color: #17253f;
}

.content-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #111c30;
}

.no-have {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 1.5rem;
}

.loading {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #17253f;
    img {
      width: 35%;
      margin-bottom: 5%;
    }
    .spinner {
      min-width: 40px;
      min-height: 40px;
      border: 5px solid rgba(255, 255, 255, 0.1);
      border-right: 5px solid #67ebb9;
      border-radius: 50%;
      animation: spinner 1s linear infinite;
    }
  }
</style>