<template>
  <div class="box box-widget">
    <!-- FILTROS HEADER -->
    <div class="box-header">
      <div class="row d-flex align-center">
        <div class="col-md-4 col-sm-12">
          <v-select
            label="Status"
            outlined
            color="#858585"
            :items="listStatus"
            item-value="value"
            item-text="text"
            hide-details
            class=""
            v-model="status"
            @change="changeStatus()"
          ></v-select>
        </div>
        <div class="col-md-5 col-sm-12">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            placeholder="Busca por, Número Pedido, Produto, OP, Cliente ..."
            hide-details
            outlined
          ></v-text-field>
        </div>
        <div class="col-md-3 col-sm-12 text-center">
          <form
            id="formFile"
            method="POST"
            class="form-horizontal"
            :action="url + '/import'"
            enctype="multipart/form-data"
          >
            <input type="hidden" name="_token" :value="token" />
            <input type="hidden" name="_method" value="POST" />
            <input
              id="file_import"
              type="file"
              name="file"
              class=""
              required
              style="display: none"
              accept=".xls, .xlsx"
              @change="selectFile()"
            />
            <v-btn
              :loading="dialogLoading"
              :disabled="dialogLoading"
              color="#28a745"
              class="ma-2 white--text"
              @click="clickImport()"
              v-if="selectedOrders.length == 0"
            >
              <v-icon left dark>mdi-cloud-upload</v-icon>
              Importar Excel
            </v-btn>
            <v-btn
              :loading="dialogLoading"
              :disabled="dialogLoading"
              color="primary"
              class="ma-2 white--text"
              @click="showDialogPlanning()"
              v-else
            >
              <v-icon left dark>mdi-state-machine</v-icon>
              {{ status == 1 ? "PLANEJAR" : "EDITAR" }}
            </v-btn>
          </form>
        </div>
      </div>
    </div>

    <!-- TABELA PRINCIPAL -->
    <div class="box-body">
      <slot></slot>

      <v-data-table
        no-data-text="Nenhuma ordem disponível"
        hide-default-footer
        :headers="header"
        :items="filteredItems"
        :items-per-page="items_per_page"
        :search="search"
        :sort-by="sortBy"
        :sort-desc="groupSortDescending"
        group-by="nr_ordem"
        no-results-text="Nenhum registro encontrado"
        class="elevation-0"
        loading-text="Carregando itens..."
        :header-props="{ sortByText: 'Ordenar por' }"
        show-select
        item-key="id"
        v-model="selectedOrders"
      >
        <template v-slot:group.header="{ items }">
          <td :colspan="header.length" style="font-size: 12px">
            <span class="font-weight-bold">Ordem:</span>
            {{ items[0].nr_ordem }}
            <span class="font-weight-bold ml-5">Qtde Movimento:</span>
            {{ sumMovimento(items) }}
            <span class="font-weight-bold ml-5">Comprimento/Altura:</span>
            {{ sumComprimento(items) }}
            <span class="font-weight-bold ml-5">Qtde:</span>
            {{ sumQtde(items) }}
          </td>
        </template>
        <template v-slot:item.cod_produto="{ item }">
          <v-chip x-small text-color="white" color="#818a91">{{
            item.cod_produto
          }}</v-chip>
        </template>
        <template v-slot:item.descricao_produto="{ item }">
          {{ item.descricao_produto }}
        </template>
        <template v-slot:item.status="{ item }">
          <v-chip
            small
            text-color="white"
            v-if="item.status == 1"
            color="warning"
            >Aguardando</v-chip
          >
          <v-chip
            small
            text-color="white"
            v-if="item.status == 2"
            color="primary"
            >Planejada</v-chip
          >
          <v-chip small text-color="white" v-if="item.status == 3" color="info"
            >Em Execução</v-chip
          >
          <v-chip
            small
            text-color="white"
            v-if="item.status == 4"
            color="secondary"
            >Pausada</v-chip
          >
          <v-chip
            small
            text-color="white"
            v-if="item.status == 5"
            color="success"
            >Finalizada</v-chip
          >
        </template>
        <template v-slot:item.dataPlanejada="{ item }">
          {{ item.dataPlanejada | moment("DD/MM/YYYY") }}
        </template>
        <template v-slot:footer>
          <v-row justify="center" class="mt-2" v-if="filteredItems.length > 0">
            <v-btn v-if="items_per_page > 15" @click="items_per_page -= 15" text
              >Mostrar menos</v-btn
            >
            <v-btn
              v-if="filteredItems.length > items_per_page"
              @click="items_per_page += 15"
              text
              >Carregar mais</v-btn
            >
          </v-row>
        </template>
      </v-data-table>
    </div>

    <!-- DIALOG LOADING IMPORTAÇÃO -->
    <v-dialog v-model="dialogLoading" hide-overlay persistent width="400">
      <v-card color="#254b6a" height="200" dark class="d-flex align-center">
        <v-card-text class="text-center">
          Importando dados
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0 mt-3"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- DIALOG PLANEJAMENTO -->
    <v-dialog v-model="dialogPlanning" max-width="1300" persistent scrollable>
      <v-card flat min-height="400" max-height="700">
        <v-card-title class="ma-2">
          <v-row class="d-flex align-center ml-0 mr-0 mt-2">
            <v-col cols="12" sm="12" md="2" class="pa-0"> Planejamento </v-col>
            <v-col cols="12" sm="12" md="3" class="pa-0">
              <v-select
                no-data-text="Nenhuma fábrica cadastrada"
                label="Unidade Fabril"
                outlined
                color="#858585"
                :items="factories"
                item-value="id"
                item-text="name"
                :loading="loadingFactories"
                :disabled="loadingFactories"
                hide-details
                class="mr-3"
                v-model="factory"
                @change="getMachines()"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="12" md="4" class="pa-0">
              <v-select
                no-data-text="Nenhuma máquina cadastrada"
                label="Máquinas"
                outlined
                color="#858585"
                :items="machines"
                item-value="id"
                item-text="name"
                :loading="loadingMachines"
                :disabled="loadingMachines"
                hide-details
                class="mr-3"
                v-model="machine"
                multiple
              ></v-select>
            </v-col>
            <v-col cols="12" sm="12" md="2" class="pa-0">
              <v-menu
                v-model="menu1"
                :close-on-content-click="false"
                max-width="290"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    hide-details
                    outlined
                    prepend-icon="mdi-calendar"
                    @blur="date = parseDate(dateFormatted)"
                    v-model="dateFormatted"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date"
                  :min="today"
                  @change="menu1 = false"
                  locale="pt-br"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="12" md="1" class="pa-0">
              <v-btn
                icon
                text
                class="pull-right"
                @click="dialogPlanning = false"
              >
                <v-icon>mdi-close-thick</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="mt-3">
          <v-data-table
            no-data-text="Nenhuma ordem disponível"
            hide-default-footer
            calculate-widths
            :headers="header2"
            :items="selectedOrders"
            :items-per-page="items_per_page2"
            class="elevation-0"
            loading-text="Carregando itens..."
            item-key="id"
            group-by="nr_ordem"
          >
            <template v-slot:group.header="{ items }">
              <td colspan="9" style="font-size: 12px">
                <span class="font-weight-bold">Ordem:</span>
                {{ items[0].nr_ordem }}
                <span class="font-weight-bold ml-5">Qtde Movimento:</span>
                {{ sumMovimento(items) }}
                <span class="font-weight-bold ml-5">Comprimento/Altura:</span>
                {{ sumComprimento(items) }}
                <span class="font-weight-bold ml-5">Qtde:</span>
                {{ sumQtde(items) }}
              </td>
            </template>
            <template v-slot:item.descricao_produto="{ item }">
              <v-chip x-small text-color="white" color="#818a91">{{
                item.cod_produto
              }}</v-chip>
              {{ item.descricao_produto }}
            </template>
            <template v-slot:item.qtde_informada="{ item }">
              {{ item.qtde_informada }}{{ item.und_informada }}
            </template>
            <template v-slot:item.capacity="{ item }">
              <v-text-field
                v-model="item.capacity"
                outlined
                hide-details
                dense
              ></v-text-field>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-row justify="center" class="ma-2">
            <v-btn
              color="blue darken-1"
              class="ma-2 white--text"
              :loading="loadingSave"
              :disabled="loadingSave || !factory || !machine"
              @click="sendProduction()"
            >
              {{ status == 1 ? "ENVIAR PARA PRODUÇÃO" : "EDITAR" }}
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<style>
.mv-22 {
  margin: 22px 0;
}

.v-middle {
  vertical-align: middle !important;
}
.v-date-picker-title__date {
  font-size: 34px;
  text-align: left;
  font-weight: 500;
  position: relative;
  overflow: hidden;
  padding-bottom: 10px;
  margin-bottom: -8px;
  padding-top: 10px;
}
</style>

<script>
import axios from "axios";
import moment from "moment";
import { format, parseISO } from "date-fns";

export default {
  props: ["items", "url", "token", "user"],
  data: function () {
    return {
      filename: "",
      loadingSave: false,
      header: [],
      today: new Date().toISOString().substr(0, 10),
      headerAguardando: [
        // { text: "#", align: "left", value: "id", },
        { text: "Nr. Docto", align: "left", value: "nr_docto", width: "100px" },
        {
          text: "Prev. Entrega",
          align: "left",
          value: "prev_entrega",
          width: "100px",
        },
        { text: "Status", align: "left", value: "status", width: "150px" },
        { text: "Nr. Ordem", align: "left", value: "nr_ordem", width: "100px" },
        {
          text: "Qtde Movimento",
          align: "left",
          value: "qtde_movimento",
          width: "100px",
        },
        {
          text: "Comprimento",
          align: "left",
          value: "comprimento_altura",
          width: "100px",
        },
        {
          text: "Qtde",
          align: "left",
          value: "qtde_informada",
          width: "100px",
        },
        {
          text: "Cód. Produto",
          align: "left",
          value: "cod_produto",
          width: "100px",
        },
        {
          text: "Produto",
          align: "left",
          value: "descricao_produto",
          width: "800px",
        },
      ],
      headerPlanejada: [
        { text: "Nr. Docto", align: "left", value: "nr_docto", width: "100px" },
        {
          text: "Prev. Entrega",
          align: "left",
          value: "prev_entrega",
          width: "100px",
        },
        { text: "Status", align: "left", value: "status", width: "150px" },
        { text: "Data Planejada", align: "left", value: "dataPlanejada" },
        // { text: "Máquina", align: "left", value: "planejada.machine.name", },
        { text: "Nr. Ordem", align: "left", value: "nr_ordem", width: "100px" },
        {
          text: "Qtde Movimento",
          align: "left",
          value: "qtde_movimento",
          width: "100px",
        },
        {
          text: "Comprimento",
          align: "left",
          value: "comprimento_altura",
          width: "100px",
        },
        {
          text: "Qtde",
          align: "left",
          value: "qtde_informada",
          width: "100px",
        },
        {
          text: "Cód. Produto",
          align: "left",
          value: "cod_produto",
          width: "100px",
        },
        {
          text: "Produto",
          align: "left",
          value: "descricao_produto",
          width: "800px",
        },
      ],
      header2: [
        { text: "Nr. Docto", align: "left", value: "nr_docto", width: "100px" },
        {
          text: "Prev. Entrega",
          align: "left",
          value: "prev_entrega",
          width: "100px",
        },
        { text: "Status", align: "left", value: "status", width: "150px" },
        { text: "Nr. Ordem", align: "left", value: "nr_ordem", width: "100px" },
        {
          text: "Qtde Movimento",
          align: "left",
          value: "qtde_movimento",
          width: "100px",
        },
        {
          text: "Comprimento",
          align: "left",
          value: "comprimento_altura",
          width: "100px",
        },
        {
          text: "Qtde",
          align: "left",
          value: "qtde_informada",
          width: "100px",
        },
        {
          text: "Produto",
          align: "left",
          value: "descricao_produto",
          width: "800px",
        },
      ],
      items_per_page: 15,
      items_per_page2: 1000,
      search: "",
      dialogLoading: false,
      selectedOrders: [],
      dialogPlanning: false,
      factory: "",
      factories: [],
      loadingFactories: false,
      machine: "",
      machines: [],
      loadingMachines: false,
      listStatus: [
        { value: 1, text: "Aguardando" },
        { value: 2, text: "Planejada" },
        { value: 3, text: "Em Execução" },
        { value: 4, text: "Pausada" },
        { value: 5, text: "Finalizada" },
      ],
      status: 1,
      date: format(parseISO(new Date().toISOString()), "yyyy-MM-dd"),
      dateFormatted: this.formatDate(new Date().toISOString().substr(0, 10)),
      menu1: false,
      sortBy: "id",
      groupSortDescending: false,
    };
  },

  created() {
    console.log("this.items");
    console.log(this.items);
    if (!this.items.data) {
      this.items.data = this.items;
    }
    this.getFactories();
    this.header = this.headerAguardando;
    console.log(this.today);
  },

  computed: {
    filteredItems() {
      return this.items.data.filter((i) => {
        return !this.status || i.status == this.status;
      });
    },
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
  },

  watch: {
    date(val) {
      this.dateFormatted = this.formatDate(this.date);
    },
  },

  methods: {
    clickImport() {
      $("#file_import").click();
    },

    selectFile() {
      if ($("#file_import").val()) {
        // ARQUIVO SELECIONADO
        this.filename = $("#file_import")
          .val()
          .match(/[^\\/]*$/)[0]; // remove C:\fakepath
        this.$swal({
          title: "Atenção!",
          text: "Deseja realmente importar o arquivo " + this.filename + "?",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#008d4c",
          cancelButtonColor: "#d4d4d4",
          confirmButtonText: "Sim, importar!",
          cancelButtonText: "Não!",
        }).then((result) => {
          if (result.value != undefined) {
            this.dialogLoading = true;
            $("#formFile").submit();
          }
        });
      }
    },

    showDialogPlanning() {
      this.dialogPlanning = true;
      if (this.selectedOrders[0].planejada) {
        console.log("this.selectedOrders[0].planejada");
        console.log(this.selectedOrders[0].planejada);
        this.machine = [];
        this.selectedOrders[0].planejada.forEach((value) => {
          this.factory = value.factory_id;
          this.machine.push(value.machine_id);
          this.dateFormatted = value.dataFormatada;
          this.date = value.date;
        });
        // this.factory = this.selectedOrders[0].planejada.factory_id;
        // this.machine = this.selectedOrders[0].planejada.machine_id;
        // this.dateFormatted = this.selectedOrders[0].planejada.dataFormatada;
        // this.date = this.selectedOrders[0].planejada.date;
        this.getMachines();
      }
    },

    getFactories() {
      this.loadingFactories = true;
      this.factories = [];
      axios
        .get("/api/factories")
        .then((response) => {
          console.log("getFactories");
          console.log(response.data);
          this.factories = response.data;
          this.loadingFactories = false;
        })
        .catch((err) => {
          console.error("Ocorreu um erro ao buscar as fábricas.");
          this.loadingFactories = false;
          console.log(err);
        });
    },

    getMachines() {
      this.loadingMachines = true;
      this.machines = [];
      axios
        .get("/api/machines/factory/" + this.factory)
        .then((response) => {
          console.log("getMachines");
          console.log(response.data);
          this.machines = response.data;
          this.loadingMachines = false;
        })
        .catch((err) => {
          console.error("Ocorreu um erro ao buscar as máquinas.");
          this.loadingMachines = false;
          console.log(err);
        });
    },

    reset() {
      this.selectedOrders = [];
      this.factory = "";
      this.machine = "";
      this.date = new Date().toISOString().substr(0, 10);
    },

    sendProduction() {
      this.$swal({
        title: "Atenção!",
        text: "Deseja realmente enviar as ordens para produção?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#008d4c",
        cancelButtonColor: "#d4d4d4",
        confirmButtonText: "Sim, enviar!",
        cancelButtonText: "Não!",
      }).then((result) => {
        if (result.value != undefined) {
          this.loadingSave = true;
          var dados = {
            orders: this.selectedOrders,
            factory: this.factory,
            machines: this.machine,
            user_id: this.user.id,
            date: this.date,
          };
          console.log("dados");
          console.log(dados);
          let url = "/api/order/send-production";
          if (this.selectedOrders[0].planejada) {
            url = "/api/order/update-production";
          }
          // return null;
          axios
            .post(url, dados)
            .then((response) => {
              console.log(url);
              console.log(response.data);
              this.reset();
              this.dialogPlanning = false;
              this.$swal({
                title: "Sucesso!",
                text: "As ordens foram enviadas para produção.",
                type: "success",
              });
              window.location.reload(true);
            })
            .catch((err) => {
              console.error("Ocorreu um erro ao salvar as ordens.");
              console.log(err);
            })
            .finally(() => {
              this.loadingSave = false;
            });
        }
      });
    },

    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    parseDate(date) {
      if (!date) return null;

      // const [month, day, year] = date.split('/')
      // return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      return moment(date, "DD/MM/YYYY").format("YYYY-MM-DD");
    },

    sumMovimento(items) {
      let sum = 0;
      items.forEach(function (item) {
        sum += parseFloat(item.qtde_movimento.replace(",", "."));
      });
      return sum.toFixed(2).replace(".", ",");
    },

    sumComprimento(items) {
      let sum = 0;
      items.forEach(function (item) {
        sum += parseFloat(item.comprimento_altura.replace(",", "."));
      });
      return sum.toFixed(2).replace(".", ",");
    },

    sumQtde(items) {
      let sum = 0;
      items.forEach(function (item) {
        sum += parseFloat(item.qtde_informada);
      });
      return sum;
    },

    changeStatus() {
      this.selectedOrders = [];
      if (this.status == 1) {
        // AGUARDANDO
        this.header = this.headerAguardando;
        this.sortBy = "id";
        this.groupSortDescending = false;
      } else if (this.status == 2) {
        // PLANEJADA
        this.header = this.headerPlanejada;
        this.sortBy = "dataPlanejada";
        this.groupSortDescending = true;
      }
    },
  },
};
</script>
