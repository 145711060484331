<script>
import axios from "axios";
import { Line } from "vue-chartjs";
export default {
  extends: Line,
  // props: ["data", "options"],
  data() {
    return {
      loading: true,
      value: [],
      data: {
        labels: ["10/05", "11/05", "12/05", "13/05", "14/05"],
        datasets: [
          {
            label: 'OEE',
            borderColor: "#67EBB9",
            borderWidth: 3,
            fill: false,
            lineTension: 0,
            pointBackgroundColor: "#67EBB9",
            pointBorderWidth: 0,
            data: [80, 88, 97, 86, 97],
          },
          {
            label: 'Disponibilidade',
            borderColor: "#9B7AAD",
            borderWidth: 3,
            fill: false,
            lineTension: 0,
            pointBackgroundColor: "#9B7AAD",
            pointBorderWidth: 0,
            data: [100, 89, 82, 94, 98],
          },
          {
            label: 'Performance',
            borderColor: "#DFA01E",
            borderWidth: 3,
            fill: false,
            lineTension: 0,
            pointBackgroundColor: "#DFA01E",
            pointBorderWidth: 0,
            data: [89, 93, 80, 100, 89],
            },
            {
            label: 'Qualidade',
            borderColor: "#495698",
            borderWidth: 3,
            fill: false,
            lineTension: 0,
            pointBackgroundColor: "#495698",
            pointBorderWidth: 0,
            data: [97, 87, 81, 82, 83],
            }
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: true,
          labels: {
            fontColor: '#fff',
            usePointStyle: true,
            boxWidth: 9,
          }
        },
        animation: {
          duration: 1,
        },
        scales: {
          xAxes: [
            {
              ticks: {
                fontColor: '#ffffffc0',
                beginAtZero: true,
              },
              gridLines: {
                color: '#ffffff30',
              }
            },
          ],
          yAxes: [
            {
              ticks: {
                fontColor: '#ffffffc0',
                // min: 0,
                max: 100,
              },
              gridLines: {
                color: '#ffffff30',
              }
            },
          ],
        },
      },
    };
  },
  methods: {
    getData() {
      // axios
      //   .get("api/dashboard/get-oee")
      //   .then((response) => {
      //     this.loading = false;
      //     // this.populateChart(response.data);
      //     this.timeoutAtt();
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
    },
    populateChart(data) {
      let base_datasets = {
        label: "",
        borderColor: "#0ff",
        borderWidth: 1,
        fill: false,
        lineTension: 0,
        pointBackgroundColor: "#0ff",
        pointBorderWidth: 0,
        data: [],
      };

      let labels = [];
      data.days.forEach((item) => {
        labels.push(item);
      });

      this.data.labels = labels;
      this.data.datasets = [];
      let datasets = [];

      let new_data = JSON.parse(JSON.stringify(base_datasets));
      new_data.label = "aaaa";
      new_data.data = data.values.value;
      new_data.borderColor = "#32abf1";
      new_data.pointBackgroundColor = "#32abf1";
      datasets.push(new_data);

      this.data.datasets = datasets;
      this.$data._chart.update();
    },
    timeoutAtt() {
      setTimeout(() => {
        this.getData();
      }, 120000);
    },
  },
  created() {
    this.getData();
  },
  mounted() {
    this.renderChart(this.data, this.options);
  },
};
</script>

<style lang="scss" scoped>
</style>