<template>
  <div style="height:100vh">
    <card-dashboard-oee :oees_list="oees_list" :date_atm="date_list" :items_select="items"></card-dashboard-oee>
  </div>
</template>

<script>
export default {
  props: ["oees","date_atm","items_select"],
  data() {
    return {
      oees_list: [],
      date_list:[],
      items:[],
    };
  },

  created() {
    this.date_list = JSON.parse(this.date_atm);
    this.oees_list = JSON.parse(this.oees);
    this.items = JSON.parse(this.items_select);
  },
};
</script>

<style lang="scss" scoped>

</style>