<template>
  <div class="box box-widget">
    <!-- <div class="box-header">
      <div class="row">
        <div class="col-md-8 col-sm-6">
          <h3 class="box-title">{{title}}</h3>
        </div>
        <div v-if="busca != 'false'" class="col-md-4 col-sm-6">
          <form method="GET" class="form-horizontal" :action="url">
            <div class="input-group">
              <input
                type="text"
                name="busca"
                class="form-control"
                placeholder="Buscar por"
                :value="busca"
              />
              <span class="input-group-btn">
                <button type="submit" class="btn btn-primary btn-flat">
                  <i class="fa fa-search"></i>
                </button>
                <a
                  :href="url"
                  class="btn btn-default btn-flat"
                  data-toggle="tooltip"
                  title="Limpar Busca"
                >
                  <i class="fa fa-list"></i>
                </a>
              </span>
            </div>
          </form>
        </div>
      </div>
    </div>-->

    <div class="box-body">
      <slot></slot>

      <form
        v-if="items.data.length > 0"
        :class="formClass"
        v-on:submit.prevent="confirmDelete"
        :action="url + '/destroy'"
        method="POST"
      >
        <input type="hidden" name="_token" :value="token" />
        <input type="hidden" name="_method" value="DELETE" />
        <table id="example1" class="table">
          <thead class="table-dark">
            <tr>
              <th v-if="!notDeletable" align="center" width="50px">
                <input
                  class="checkbox-delete"
                  id="checkbox-delete-origin"
                  type="checkbox"
                  name="registro[]"
                  data-toggle="tooltip"
                  title="Marcar tudo"
                  v-on:click="checkAll"
                />
              </th>
              <th v-for="(title, index) in titles" :key="index">{{title}}</th>
              <th v-if="actions != 'false'" style="width: 225px;">Ações</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in items.data" :key="index">
              <th v-if="!notDeletable" align="center" class="v-middle">
                <input class="checkbox-delete" type="checkbox" name="registro[]" :value="item.code" />
              </th>
              <td v-for="(field, index) in item" :key="index" class="v-middle">
                <span v-if="field === null">{{field}}</span>
                <span v-else-if="!field.type && !Array.isArray(field)">{{field}}</span>

                <span v-else-if="field.type == 'img'">
                  <img :src="field.src" style="max-width:150px; max-height:150px;" />
                </span>

                <span
                  v-else-if="field.type == 'label'"
                  class="label"
                  :class="'label-' + field.status"
                  style="color:#fff;"
                >{{ field.text }}</span>
                <span
                  class="mr-2 px-2 py-0 rounded-pill badge"
                  v-else-if="isMultiBadge(field)"
                  v-for="(campo, index) in field"
                  :key="index"
                  :class="'badge-' + campo.status"
                  style="color:#fff;"
                >
                  <span>{{campo.text}}</span>
                </span>
                <span
                  v-else-if="field.type == 'badge' "
                  class="badge"
                  :class="'badge-' + field.status"
                  style="color:#fff;"
                >{{ field.text }}</span>
              </td>
              <td v-if="!actions && actions != 'false'" class="v-middle">
                <a
                  data-toggle="tooltip"
                  class="badge ml-10 badge-primary"
                  :href="url + '/' + item.code + '/edit'"
                  title="Editar"
                  style="color:#fff;"
                >
                  <span>Editar</span>
                </a>&nbsp;
              </td>
              <td class="v-middle" v-if="actions && actions != 'false'">
                <span v-for="(action, index) in actions" :key="index">
                  <span v-if="action.label == 'Desativar'">
                    <a
                      v-if="item.status['text'] != 'Inativo'"
                      data-toggle="tooltip"
                      class="badge mb-1"
                      :class="'badge-' + action.color"
                      :href="url + '/' + actionUrl(action.path, item.code)"
                      :title="action.label"
                      style="color:#fff;"
                    >
                      <span>{{action.icon}}</span>
                    </a>
                    <a
                      v-if="item.status['text'] == 'Inativo'"
                      data-toggle="tooltip"
                      class="badge mb-1"
                      :class="'badge-' + action.color"
                      :href="url + '/' + actionUrl('{item}/enable', item.code)"
                      title="Ativar"
                      style="color:#fff;"
                    >
                      <span>Ativar</span>
                    </a>&nbsp;
                  </span>
                  <span v-else-if="action.label == 'Excluir'">
                    <a
                      data-toggle="tooltip"
                      class="badge mb-1"
                      :class="'badge-' + action.color"
                      @click="confirmDelete(item)"
                      :title="action.label"
                      style="color:#fff;"
                    >
                      <span>{{action.icon}}</span>
                    </a>&nbsp;
                  </span>
                  <span v-else>
                    <a
                      data-toggle="tooltip"
                      class="badge btn-sm mb-1"
                      :class="'badge-' + action.color"
                      :title="action.label"
                      :href="url + '/' + actionUrl(action.path, item.code)"
                      style="color:#fff;"
                    >
                      <span>{{action.icon}}</span>
                    </a>&nbsp;
                  </span>
                </span>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td :colspan="(titles.length + 2)" align="left" vertical-align="center">
                <button v-if="!notDeletable"
                  type="submit"
                  class="mv-22 btn btn-flat btn-danger"
                  :disabled="btnDelete.disabled"
                >
                  <i :class="btnDelete.icon"></i>
                  {{ btnDelete.text }}
                </button>
                <slot name="pagination"></slot>
              </td>
            </tr>
          </tfoot>
        </table>
      </form>

      <div v-if="items.data.length == 0" class="callout callout-default">
        <h4>Nenhum registro encontrado!</h4>
      </div>
    </div>
  </div>
</template>

<style>
.mv-22 {
  margin: 22px 0;
}

.v-middle {
  vertical-align: middle !important;
}
</style>

<script>
export default {
  props: [
    "title",
    "titles",
    "items",
    "busca",
    "url",
    "token",
    "formClass",
    "actions"
  ],
  data: function() {
    return {
      notDeletable: true,
      deleteItems: [],
      btnDelete: {
        icon: "fa fa-trash",
        text: "Excluir registros selecionados",
        disabled: false
      }
    };
  },
  created() {
    console.log(this.items);
    if (!this.items.data) {
      this.items.data = this.items;
    }
  },
  methods: {
    isMultiBadge(field) {
      if (Array.isArray(field) && field.length > 0) {
        if (field[0].type == "multiBadge") {
          return true;
        }
      }
      return false;
    },
    checkAll: function() {
      var checkboxOrigin = document.getElementById("checkbox-delete-origin");
      var checkboxes = document.getElementsByClassName("checkbox-delete");
      for (var i = 0, n = checkboxes.length; i < n; i++) {
        checkboxes[i].checked = checkboxOrigin.checked;
      }
    },
    confirmDelete: function(item) {
      this.$swal({
        title: "Atenção!",
        text: "Deseja realmente excluir os registros selecionados?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#008d4c",
        cancelButtonColor: "#d4d4d4",
        confirmButtonText: "Sim, deletar!",
        cancelButtonText: "Não!"
      }).then(result => {
        if (result.value != undefined) {
          window.location.replace(
            this.url + "/" + this.actionUrl("{item}/destroy", item.code)
          );
        }
        this.btnDelete.icon = "fa fa-spinner fa-pulse";
        this.btnDelete.text = "Excluindo";
        this.btnDelete.disabled = true;
      });
    },
    actionUrl: (path, id) => {
      return path.replace(/{item}/g, id);
    }
  }
};
</script>
